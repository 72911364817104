import { InverterProviders } from '@store/enums';

export enum MarketingTileTypes {
    NONE = '',
    PV = 'pv',
    BATTERY = 'battery',
    HC = 'hc',
    SL = 'sl',
    SP = 'sp',
    WALLBOX = 'wallbox',
    ELECTRIC_CAR = 'electric-car',
}

export enum CMSWidgetTypes {
    SOLAR = 'referral-solar-customers',
    SMART_METER = 'referral-smart-meter-customers',
    SUPPLY = 'referral-supply-customers',
}

export interface MarketingSection {
    buttonLabel: string;
    buttonUrl: string;
    title: string;
    content: string;
    marketingGroupType: MarketingTileTypes;
}

export interface HeatingInsights {
    buttonLabel: string;
    buttonUrl: string;
    title: string;
    content: string;
}

export interface MarketingItem {
    type: MarketingTileTypes;
    sections: MarketingSection[];
}

export interface FAQItem {
    categorySlug: string;
    category: string;
    title: string;
    content: string;
    slug: string;
}

export interface CMSSurveyItem {
    id: string;
    fields: Omit<CMSSurvey, 'id'> | undefined;
}

export interface CMSIncidentMessageItem {
    id: string;
    fields: CMSIncidentMessage | undefined;
}
export interface PreInstallationItem {
    id: string;
    title: string;
    message: string;
    confirmation?: string;
    stepName?: string;
}

export interface CMSWidgetItem {
    id: string;
    type: CMSWidgetTypes;
    heading: string;
    title: string;
    content: string;
    buttonText: string;
    buttonLink: string;
}

export interface CMSPrivacyItem {
    title: string;
    content: string;
}

export interface CMSTermsItem {
    content: string;
    version?: string;
    title?: string;
    edited?: string;
}

export enum CMSDataTypes {
    TERMS = 'terms',
    FAQ = 'faq',
    CONTACT = 'contact',
    MARKETING = 'marketing',
    MARKETING_SECTION = 'marketingSection',
    PRE_INSTALLATION_SALE = 'sales',
    SURVEYS = 'surveys',
    INCIDENT_MESSAGES = 'incidentMessages',
    HEATING_INSIGHTS = 'heatingInsights',
    PRIVACY = 'privacy',
}

export enum CMSActionTypes {
    ERROR = 'CMS_ERROR',
    LOADING = 'CMS_LOADING',
    SET_DATA = 'CMS_SET_DATA',
}

export interface CMSIncidentMessage extends CMSUUIDModel {
    active: boolean;
    content: string;
    solarVendorsList?: InverterProviders[];
}

interface CMSUUIDModel {
    uuid: string;
}

export type CountryCode =
    | 'en'
    | 'de'
    | 'it'
    | 'sv'
    | 'hu'
    | 'pl'
    | 'cs'
    | 'nl'
    | 'hr';

export enum ContentStateEnum {
    Draft = 'draft',
    Published = 'published',
}

export interface Metadata {
    createdAt?: string;
    updatedAt?: string;
    createdBy?: string;
    updatedBy?: string;
    publishedAt?: string;
    publishedBy?: string;
}
export interface CMSDataModel {
    id: string;
    tenantId: string;
    state: ContentStateEnum;
    language: string;
    schemaName: string;
    fields: {
        [key in CountryCode]?: {
            slug?: string;
            category?: string;
            categorySlug?: string;
            sortIndex?: string;
            title?: string;
            name?: string;
            content: string;
            version?: string;
            type?: string;
            buttonHyperLink?: string;
            buttonLabel?: string;
            frameLegend?: string;
            confirmation?: string;
            message?: string;
            heading?: string;
            buttonText?: string;
            buttonLink?: string;
            active?: boolean;
            stepName?: string;
            backgroundImage?: string;
            marketingGroupType?: string;
        };
    };
    meta?: Metadata;
}

export interface CMSFieldModel {
    Content: string | string[];
    content?: string;
    Title: string;
    title?: string;
    heading?: string;
    buttonText?: string;
    buttonLink?: string;
    date?: string;
    slug: string;
    category?: string;
    message?: string;
    version?: string;
    Index?: number;
    type?: string;
    confirmation?: string;
    sortIndex?: number;
    marketingSections: {
        fields: {
            buttonHyperLink: string;
            buttonLabel: string;
            content: string;
            frameLegend: string;
            title: string;
        };
        uuid: string;
    }[];
}

export interface CMSResponseModel {
    results: CMSDataModel[];
    _metainfo: {
        currentPage: number;
        pageCount: number;
        totalCount: number;
    };
}

export enum CMSSegments {
    SOLAR = 'Solar',
    EMOBILITY = 'Emobility',
    HEATING_COOLING = 'Heating-Cooling',
    SMART_METER = 'Smart-Meter',
    NO_DEVICE = 'No-Device',
}

export interface CMSSurvey extends CMSFieldModel {
    id: string;
    active: boolean;
    start: string;
    end: string;
    mobile?: string;
    text?: string;
    customerSegments?: CMSSegments[];
    solarVendorsList?: any[];
    loginCount?: number;
}
export interface CMSStateItem<T> {
    data: T;
    edited?: string;
    version?: string;
    error: null | string;
    networkError: null | Error;
    loading: boolean;
}

export interface CMSState {
    [CMSDataTypes.TERMS]: CMSStateItem<CMSTermsItem | CMSTermsItem[]>;
    [CMSDataTypes.FAQ]: CMSStateItem<FAQItem[]>;
    [CMSDataTypes.MARKETING]: CMSStateItem<MarketingItem[]>;
    [CMSDataTypes.CONTACT]: CMSStateItem<string>;
    [CMSDataTypes.PRE_INSTALLATION_SALE]: CMSStateItem<PreInstallationItem[]>;
    [CMSDataTypes.SURVEYS]: CMSStateItem<CMSSurvey[]>;
    [CMSDataTypes.INCIDENT_MESSAGES]: CMSStateItem<CMSIncidentMessageItem[]>;
}

interface CMSActionData {
    type: CMSActionTypes;
    dataType: CMSDataTypes;
    payload: CMSStateItem<
        | string
        | Partial<CMSFieldModel>
        | Partial<CMSFieldModel>[]
        | Partial<CMSDataModel>[]
    >;
}

interface CMSActionLoading {
    type: CMSActionTypes.LOADING;
    dataType: CMSDataTypes;
}

export type CMSAction = CMSActionData | CMSActionLoading;

import { Store } from 'redux';

import { isDefined } from '@eon-home/react-library';

import {
    UserApi,
    ResponseError,
    SystemHealthApi,
    UriResponseModel,
    AuthenticationApi,
    SystemHealthModel,
    UserPreferenceModel,
    SystemHealthModelStatusEnum,
} from '@swagger-http';

import { getLocale } from '@store/actions';
import { RootState } from '@store/types';
import { AuthActionTypes } from '@store/enums';
import { Scope, Routes, CIAM_COUNTRIES } from '@tools/enums';
import {
    toBase64,
    goToLogin,
    handleError,
    checkForScopes,
    setTokenInLocalStorage,
    setScopesInLocalStorage,
    createRequestConfiguration,
    generateAccessTokenThreshold,
} from '@tools/utils';
import { TokenSet, tryOauthTokenRefresh } from '@tools/utils/oauth';

export const setToken = (
    result: Record<'access_token' | 'refresh_token', string>,
) => ({
    type: AuthActionTypes.SET_TOKEN,
    payload: {
        token: result.access_token,
        threshold: generateAccessTokenThreshold(result.access_token),
        requesting: false,
        refreshToken: result.refresh_token,
    },
});

const apiTokenRefresh = (refreshToken: string): Promise<TokenSet> =>
    new AuthenticationApi(createRequestConfiguration()).authenticationToken({
        authenticationRefreshRequestModel: {
            // @ts-ignore
            refresh_token: refreshToken,
        },
    }) as Promise<TokenSet>;

export const getNewToken = (store: Store<RootState>): Promise<void> =>
    new Promise((resolve, reject) => {
        const auth = store.getState().auth;
        tryOauthTokenRefresh(auth.token, auth.refreshToken)
            .then((data) => data ?? apiTokenRefresh(auth.refreshToken))
            .then((response: TokenSet) => {
                setTokenInLocalStorage(
                    response.access_token,
                    response.refresh_token,
                );

                store.dispatch(setToken(response));

                resolve();
            })
            .catch(async (е: ResponseError) => {
                await handleError(е, 'Error when getting a new token:');

                goToLogin();

                return reject(е);
            });
    });

export const getLoginUri = async (): Promise<string | void> => {
    const locale = getLocale().toUpperCase();
    const lang: string = (CIAM_COUNTRIES as any)[locale];

    try {
        const result: UriResponseModel = await new AuthenticationApi(
            createRequestConfiguration(),
        ).authenticationLink({ lang });

        return result.uri;
    } catch (e) {
        return Promise.reject(
            await handleError(e, 'Error when getting the login URL:'),
        );
    }
};

export const login = async (code: string, state: string): Promise<any> => {
    const authorization = 'Basic ' + toBase64(`${code}:${state}`);

    try {
        const result: any = await new AuthenticationApi(
            createRequestConfiguration(),
        ).authenticationLogin(
            { authorization },
            {
                credentials: 'include',
            },
        );

        setTokenInLocalStorage(result.access_token, result.refresh_token);
        setScopesInLocalStorage(result.scope);

        return Promise.resolve(result);
    } catch (e) {
        await handleError(e, 'Error when logging in:');

        return Promise.reject(e);
    }
};

export const trackLogin = async (): Promise<void> => {
    if (!checkForScopes([Scope.ME_WRITE])) {
        window.location.href = Routes.BASE;

        return;
    }

    const userAPI = new UserApi(createRequestConfiguration());

    try {
        const count = await userAPI.userPreferences().then((response) => {
            let loginCount: UserPreferenceModel | number | void = response.find(
                ({ id }: { id: string }) => id === 'loginCount',
            );

            loginCount = isDefined(loginCount, 'value.count')
                ? ++(loginCount!.value as Record<'count', number>).count
                : 1;

            return loginCount;
        });

        await userAPI.userUpdatePreference({
            key: 'loginCount',
            userPreferenceAddModel: {
                value: [{ count }],
            },
        });
    } catch (e) {
        await handleError(e, "Error when tracking user's login count:");
    } finally {
        window.location.href = Routes.BASE;
    }
};

export const isPlatformRunning = async (): Promise<boolean> => {
    // Remove this when the backend implements CIAM health check
    const hasLoginURI = await getLoginUri()
        .then(() => true)
        .catch(() => false);

    if (!hasLoginURI) {
        return false;
    }

    return new SystemHealthApi(createRequestConfiguration())
        .systemHealthGetHealth()
        .then(async (response: SystemHealthModel) => {
            const { status } = response;

            if (status !== SystemHealthModelStatusEnum.Available) {
                await handleError(
                    new ResponseError(
                        new Response('System status is not available.'),
                    ),
                    `System status: ${status}. More info:`,
                );
            }

            return status === SystemHealthModelStatusEnum.Available;
        })
        .catch(async (e: ResponseError) => {
            await handleError(e, 'Error getting system health status:');

            return false;
        });
};

export const enum RegistrationFormModes {
    STEPUP = 'step-up',
    REGISTER = 'register',
}

export const enum ScreenTypes {
    DETAILS = 'detailsView',
    ENERGY_FLOW = 'energyFlowView',
    SOLAR_TOP = 'solarViewTop',
    SOLAR_BOTTOM = 'solarViewBottom',
}

export const enum MarketingTileTypes {
    NONE = '',
    BATTERY = 'battery',
    INVERTER = 'pv',
    WALLBOX = 'wallbox',
    ELECTRIC_CAR = 'electric-car',
    SMART_METER = 'smt',
    SMART_LIGHTS = 'sl',
    SMART_SOCKETS = 'sp',
    HEATING_COOLING = 'hc',
}

export enum GraphTypes {
    GRID = 'GRID',
    BATTERY = 'BATTERY',
    INVERTER = 'INVERTER',
    EMOBILITY = 'EMOBILITY',
    SOLAR_CLOUD = 'SOLAR_CLOUD',
    SMART_METER = 'SMART_METER',
    IMPORT_EXPORT = 'IMPORT_EXPORT',
    OVERVIEW_POWER = 'OVERVIEW_POWER',
    OVERVIEW_ENERGY = 'OVERVIEW_ENERGY',
    SMART_METER_COSTS = 'SMART_METER_COSTS',
    SOLAR_CLOUD_BALANCE = 'SOLAR_CLOUD_BALANCE',
}

export enum DownloadFormat {
    CSV = 'csv',
    JSON = 'json',
}

export enum GridxReportFormat {
    CSV = 'CSV',
    PDF = 'PDF',
}

import { composeClassName } from '@tools/utils';
import { Button, Icon } from '@eon-home/react-library';

import { CloseButton } from '@components';

import { useTranslation } from 'react-i18next';

import HemsDevices from '@assets/icons/misc/hems-devices.svg';

import { SettingsActionTypes } from '@store/enums';
import { useDispatch } from 'react-redux';
import { useAppLoading } from '@store/selectors';

export const WELCOME_HOME_SMART_CUSTOMERS_KEY = 'welcome-home-smart-customers';

export const WelcomeHomeSmartCustomers = () => {
    const className = 'c-sticky-widget';
    const dispatch = useDispatch();
    const appLoading = useAppLoading();
    const { t } = useTranslation();

    if (appLoading) {
        return null;
    }

    const onClose = () => {
        dispatch({
            type: SettingsActionTypes.SET_WELCOME_HOME_SMART_CUSTOMERS,
        });
        localStorage.setItem(WELCOME_HOME_SMART_CUSTOMERS_KEY, 'true');
    };

    return (
        <div
            className={composeClassName(className, [
                'welcome-home-smart-customers',
            ])}
        >
            <figure className={`${className}__image`}>
                <Icon src={HemsDevices} />
            </figure>

            <div className={`${className}__content`}>
                <h2>{t('E.ON Home Smart enabled')}</h2>
                <p>
                    {t(
                        'You have now access to E.ON Home Smart features, including Smart charging bonus.',
                    )}
                </p>
            </div>

            <div className={`${className}__actions`}>
                <Button
                    type="button"
                    className="c-btn--white"
                    onClick={onClose}
                >
                    {t('I understand')}
                </Button>
            </div>

            <CloseButton onClose={onClose} />
        </div>
    );
};

export default WelcomeHomeSmartCustomers;

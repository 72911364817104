import { CONSENT_COOKIE } from '@tools/constants';

type AnalyticsEventMeta = Record<string, string>;
type AnalyticsEventType = 'generic_event';

type AnalyticsEventName =
    | 'account_delete_declined'
    | 'account_deleted'
    | 'default_settings_click'
    | 'delete_account_click'
    | 'electric_car_click'
    | 'electricity_calendar'
    | 'electricity_change_range'
    | 'electricity_chart_slide'
    | 'EO_chart_toggle'
    | 'EO_to_battery_on'
    | 'EO_to_battery_off'
    | 'EO_to_grid_on'
    | 'EO_to_grid_off'
    | 'EO_direct_consumption_generation_on'
    | 'EO_direct_consumption_generation_off'
    | 'EO_from_battery_on'
    | 'EO_from_battery_off'
    | 'EO_from_grid_on'
    | 'EO_from_grid_off'
    | 'EO_direct_consumption_consumption_on'
    | 'EO_direct_consumption_consumption_off'
    | 'power_overview_page '
    | 'PO_calender_data_rage_change '
    | 'PO_chart_navigation'
    | 'PO_chart_flag'
    | 'PO_chart_toggle'
    | 'PO_grid_on'
    | 'PO_grid_off'
    | 'PO_battery_on'
    | 'PO_battery_off'
    | 'PO_consumption_on'
    | 'PO_consumption_off'
    | 'PO_self_consumption_on'
    | 'PO_self_consumption_off'
    | 'grid_view'
    | 'GV_data_range_change'
    | 'GV_calendar_data_range'
    | 'GV_chart_navigation'
    | 'GV_chart_flag'
    | 'GV_chart_toggle'
    | 'GV_generation_battery_on'
    | 'GV_generation_battery_off'
    | 'GV_self-sufficiency_on'
    | 'GV_self-sufficiency_off'
    | 'GV_previous_day_on'
    | 'GV_previous_day_off'
    | 'electricity_click'
    | 'electricity_navigate'
    | 'emobility_click'
    | 'ev_start_charge'
    | 'ev_stop_charge'
    | 'intelligent_lights_click'
    | 'language_changed'
    | 'login'
    | 'logout'
    | 'logo_click'
    | 'main_date_range_switch'
    | 'overview_click'
    | 'overview_data_download'
    | 'overview_data_download_cancel'
    | 'overview_data_request'
    | 'overview_range_changed'
    | 'overview_view_changed'
    | 'privacy_click'
    | 'pv_bottom_range_changed'
    | 'pv_calendar'
    | 'pv_chart_navigate'
    | 'pv_chart_slide'
    | 'pv_chart_toogle'
    | 'pv_click'
    | 'pv_CO2_faq'
    | 'pv_data_download'
    | 'pv_data_download_cancel'
    | 'pv_data_request'
    | 'pv_exported_turned_off'
    | 'pv_exported_turned_on'
    | 'pv_previous_day_off'
    | 'pv_previous_day_on'
    | 'pv_top_range_change'
    | 'pv_forecast_on'
    | 'pv_forecast_off'
    | 'generation_to_storage_switch'
    | 'storage_view_discharging_on'
    | 'storage_view_discharging_off'
    | 'storage_view_state_of_charge_on'
    | 'storage_view_state_of_charge_off'
    | 'storage_view_previous_day_on'
    | 'storage_view_previous_day_off'
    | 'registration_click'
    | 'regulate_conditioning_click'
    | 'home'
    | 'energy'
    | 'more'
    | 'insights'
    | 'self_onboarding_start'
    | 'self_onboarding_canceled'
    | 'self_onboarding_contact_mail'
    | 'self_onboarding_adding_email_confirmed'
    | 'self_onboarding_scan_input'
    | 'self_onboarding_info'
    | 'self_onboarding_success'
    | 'self_onboarding_fail'
    | 'settings_smart_home_faq_click'
    | 'settings_smart_home_lightwave_connect_click'
    | 'settings_smart_home_pair_smart_lights'
    | 'settings_smart_home_pair_tado'
    | 'settings_smart_home_tado_connect_click'
    | 'settings_smart_home_unlink_smart_lights'
    | 'settings_smart_home_unlink_tado'
    | 'settings_ev_change'
    | 'settings_ev_click'
    | 'settings_ev_link'
    | 'settings_ev_link_failed_retry'
    | 'settings_ev_link_success_continue'
    | 'settings_ev_save'
    | 'settings_ev_unlink'
    | 'theme_preferences'
    | 'data_request'
    | 'terms_of_use'
    | 'faqs'
    | 'search_in_faqs'
    | 'no_results_in_faq'
    | 'contact'
    | 'acknowledgement'
    | 'show_smart_devices_click'
    | 'smart_home_cooling_adjusted'
    | 'smart_home_cooling_history'
    | 'smart_home_cooling_off'
    | 'smart_home_cooling_on'
    | 'smart_home_create_space_click'
    | 'smart_home_heating_history'
    | 'smart_home_heating_off'
    | 'smart_home_heating_on'
    | 'smart_home_lights_adjusted'
    | 'smart_home_lights_off'
    | 'smart_home_lights_on'
    | 'smart_home_refresh'
    | 'smart_home_socket_history'
    | 'smart_home_socket_off'
    | 'smart_home_socket_on'
    | 'smart_home_socket_resolution'
    | 'smart_home_sort_by'
    | 'smart_home_space_created'
    | 'smart_home_water_off'
    | 'smart_home_water_on'
    | 'smart_sockets_click'
    | 'smart_home_insights_slide_view_top'
    | 'smart_home_insights_add_device'
    | 'smart_home_insights_slide_view_bottom'
    | 'step_up_reg_save'
    | 'step_up_reg_terms'
    | 'view_page'
    | 'cookie_policy'
    | 'marketing_box_click'
    | 'disable_guided_tour'
    | 'show_guided_tour'
    | 'manage_tariff_rate_details'
    | 'save_tariff_rate_details'
    | 'add_new_tariff_interval'
    | 'delete_tariff_detail'
    | 'edit_price'
    | 'pv_insights'
    | 'ev_insights'
    | 'view_all_charging_sessions'
    | 'charging_history_month_resolution_change'
    | 'charging_history_year_resolution_change'
    | 'charging_history_data_range_change'
    | 'travel_cost_information'
    | 'activate_smart_charging'
    | 'deactivate_smart_charging'
    | 'personalize_smart_charging'
    | 'save_smart_charging'
    | 'discard_smart_charging_changes'
    | 'activate_full_charge_mode'
    | 'deactivate_full_charge_mode'
    | 'dynamic_price_charging_activate'
    | 'dynamic_price_charging_deactivate'
    | 'solar_charging_activate'
    | 'solar_charging_deactivate'
    | 'ev_view_data_range_change'
    | 'ev_view_calendar_data_range_change'
    | 'ev_view_chart_flag'
    | 'ev_view_chart_navigation'
    | 'ev_view_chart_toggle'
    | 'ev_view_previous_week_view_on'
    | 'ev_view_previous_week_view_off';

type AnalyticsEventCategory =
    | 'Demo'
    | 'Electric Vehicle'
    | 'Electricity'
    | 'Energy Flow'
    | 'Navigation'
    | 'Overview'
    | 'Self Onboarding'
    | 'Settings'
    | 'Smart Home'
    | 'Solar & Battery'
    | 'Step-up Registration'
    | 'Top bar'
    | 'Utility'
    | 'Tariff'
    | 'Insights'
    | 'Smart Charging'
    | 'Electric Vehicle';

export type AnalyticsEventKey = keyof typeof analytics;
export interface AnalyticsEvent {
    event: AnalyticsEventType;
    event_name: AnalyticsEventName;
    event_category: AnalyticsEventCategory;
    type?: string;
    view_page?: AnalyticsEventMeta;
    data_download?: AnalyticsEventMeta;
    language_changed?: AnalyticsEventMeta;
    smart_home_sort_by?: AnalyticsEventMeta;
    main_date_range_switch?: AnalyticsEventMeta;
    overview_data_download?: AnalyticsEventMeta;
    overview_range_changed?: AnalyticsEventMeta;
    pv_bottom_range_changed?: AnalyticsEventMeta;
    electricity_change_range?: AnalyticsEventMeta;
}

const ga = (
    event: AnalyticsEventType,
    event_name: AnalyticsEventName,
    event_category: AnalyticsEventCategory,
    details?: Omit<AnalyticsEvent, 'event' | 'event_name' | 'event_category'>,
): void => {
    const cookiesConsent = localStorage.getItem(CONSENT_COOKIE) === '0';

    if (!cookiesConsent) return;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event,
        event_name,
        event_category,
        ...(details || {}),
    });
};

// prettier-ignore
export const analytics: Record<string, (...args: string[]) => void> = {
    // Utility category
    viewPage: (page: string): void => ga('generic_event', 'view_page', 'Utility', { view_page: { page } }),

    // Demo Category
    showGuidedTour: (): void => ga('generic_event', 'disable_guided_tour', 'Demo'),
    disableGuidedTour: (): void => ga('generic_event', 'disable_guided_tour', 'Demo'),

    // Electric Vehicle Category
    wallboxStartCharging: (): void => ga('generic_event', 'ev_start_charge', 'Electric Vehicle'),
    wallboxStopCharging: (): void => ga('generic_event', 'ev_stop_charge', 'Electric Vehicle'),

    // Electricity Category
    overviewChangeDateRange: (newRange: string): void => ga('generic_event', 'electricity_change_range', 'Electricity', { electricity_change_range: { newRange } } ),
    overviewChangeDate: (): void => ga('generic_event', 'electricity_calendar', 'Electricity'),
    overviewNavigate: (): void => ga('generic_event', 'electricity_navigate', 'Electricity'),
    overviewSlide: (): void => ga('generic_event', 'electricity_chart_slide', 'Electricity'),
    eoChartToggle: (): void => ga('generic_event', 'EO_chart_toggle', 'Electricity'),
    eoToBatteryOn: (): void => ga('generic_event', 'EO_to_battery_on', 'Electricity'),
    eoToBatteryOff: (): void => ga('generic_event', 'EO_to_battery_off', 'Electricity'),
    eoToGridOn: (): void => ga('generic_event', 'EO_to_grid_on', 'Electricity'),
    eoToGridOff: (): void => ga('generic_event', 'EO_to_grid_off', 'Electricity'),
    eoDirectConsumptionGenerationOn: (): void => ga('generic_event', 'EO_direct_consumption_generation_on', 'Electricity'),
    eoDirectConsumptionGenerationOff: (): void => ga('generic_event', 'EO_direct_consumption_generation_off', 'Electricity'),
    eoFromBatteryOn: (): void => ga('generic_event', 'EO_from_battery_on', 'Electricity'),
    eoFromBatteryOff: (): void => ga('generic_event', 'EO_from_battery_off', 'Electricity'),
    eoFromGridOn: (): void => ga('generic_event', 'EO_from_grid_on', 'Electricity'),
    eoFromGridOff: (): void => ga('generic_event', 'EO_from_grid_off', 'Electricity'),
    eoDirectConsumptionConsumptionOn: (): void => ga('generic_event', 'EO_direct_consumption_consumption_on', 'Electricity'),
    eoDirectConsumptionConsumptionOff: (): void => ga('generic_event', 'EO_direct_consumption_consumption_off', 'Electricity'),
    powerOverviewPage : (): void => ga('generic_event', 'power_overview_page ', 'Electricity'),
    poCalenderDataRageChange : (): void => ga('generic_event', 'PO_calender_data_rage_change ', 'Electricity'),
    poChartNavigation: (): void => ga('generic_event', 'PO_chart_navigation', 'Electricity'),
    poChartFlag: (): void => ga('generic_event', 'PO_chart_flag', 'Electricity'),
    poChartToggle: (): void => ga('generic_event', 'PO_chart_toggle', 'Electricity'),
    poGridOn: (): void => ga('generic_event', 'PO_grid_on', 'Electricity'),
    poGridOff: (): void => ga('generic_event', 'PO_grid_off', 'Electricity'),
    poBatteryOn: (): void => ga('generic_event', 'PO_battery_on', 'Electricity'),
    poBatteryOff: (): void => ga('generic_event', 'PO_battery_off', 'Electricity'),
    poConsumptionOn: (): void => ga('generic_event', 'PO_consumption_on', 'Electricity'),
    poConsumptionOff: (): void => ga('generic_event', 'PO_consumption_off', 'Electricity'),
    poSelfConsumptionOn: (): void => ga('generic_event', 'PO_self_consumption_on', 'Electricity'),
    poSelfConsumptionOff: (): void => ga('generic_event', 'PO_self_consumption_off', 'Electricity'),
    gridView: (): void => ga('generic_event', 'grid_view', 'Electricity'),
    gvDataRangeChange: (): void => ga('generic_event', 'GV_data_range_change', 'Electricity'),
    gvCalendarDataRange: (): void => ga('generic_event', 'GV_calendar_data_range', 'Electricity'),
    gvChartNavigation: (): void => ga('generic_event', 'GV_chart_navigation', 'Electricity'),
    gvChartFlag: (): void => ga('generic_event', 'GV_chart_flag', 'Electricity'),
    gvChartToggle: (): void => ga('generic_event', 'GV_chart_toggle', 'Electricity'),
    gvGenerationBatteryOn: (): void => ga('generic_event', 'GV_generation_battery_on', 'Electricity'),
    gvGenerationBatteryOff: (): void => ga('generic_event', 'GV_generation_battery_off', 'Electricity'),
    gvSelfSufficiencyOn: (): void => ga('generic_event', 'GV_self-sufficiency_on', 'Electricity'),
    gvSelfSufficiencyOff: (): void => ga('generic_event', 'GV_self-sufficiency_off', 'Electricity'),
    gvPreviousDayOn: (): void => ga('generic_event', 'GV_previous_day_on', 'Electricity'),
    gvPreviousDayOff: (): void => ga('generic_event', 'GV_previous_day_off', 'Electricity'),

    // Energy Flow
    cookiePolicy: (): void => ga('generic_event', 'cookie_policy', 'Energy Flow'),
    marketingBoxClick: (): void => ga('generic_event', 'marketing_box_click', 'Energy Flow'),

    // Navigation Category
    login: (): void => ga('generic_event', 'login', 'Navigation'),
    logout: (): void => ga('generic_event', 'logout', 'Navigation'),
    energyFlowChangeResolution: (newRange: string): void => ga('generic_event', 'main_date_range_switch', 'Navigation', { main_date_range_switch: { newRange } }),
    energyFlowEvCircle: (): void => ga('generic_event', 'electric_car_click', 'Navigation'),
    energyFlowEvButton: (): void => ga('generic_event', 'emobility_click', 'Navigation'),
    energyFlowSmartDevicesButton: (): void => ga('generic_event', 'show_smart_devices_click', 'Navigation'),
    energyFlowPvCircle: (): void => ga('generic_event', 'pv_click', 'Navigation'),
    energyFlowHouseholdCircle: (): void => ga('generic_event', 'overview_click', 'Navigation'),
    energyFlowGridCircle: (): void => ga('generic_event', 'electricity_click', 'Navigation'),
    energyFlowSmartLightsCircle: (): void => ga('generic_event', 'intelligent_lights_click', 'Navigation'),
    energyFlowSmartPlugsCircle: (): void => ga('generic_event', 'smart_sockets_click', 'Navigation'),
    energyFlowSmartHeatingCircle: (): void => ga('generic_event', 'regulate_conditioning_click', 'Navigation'),
    home: (): void => ga('generic_event', 'home', 'Navigation'),
    energy: (): void => ga('generic_event', 'energy', 'Navigation'),
    more: (): void => ga('generic_event', 'more', 'Navigation'),
    insights: (): void => ga('generic_event', 'insights', 'Navigation'),

    // Overview category
    overviewViewChange: (): void => ga('generic_event', 'overview_view_changed', 'Overview'),
    overviewRangeChange: (newRange: string): void => ga('generic_event', 'overview_range_changed', 'Overview', { overview_range_changed: { newRange } }),
    overviewDataRequest: (): void => ga('generic_event', 'overview_data_request', 'Overview'),
    overviewDataDownload: (resolution: string ): void => ga('generic_event', 'overview_data_download', 'Overview', { overview_data_download: { resolution } } ),
    overviewDataDownloadCancel: (): void => ga('generic_event', 'overview_data_download_cancel', 'Overview'),

    // Self onboarding category
    // TODO: discuss with the PO as all events are set to track Fronius
    selfOnboardingStart: (): void => ga('generic_event', 'self_onboarding_start', 'Self Onboarding'),
    selfOnboardingCanceled: (type: string): void => ga('generic_event', 'self_onboarding_canceled', 'Self Onboarding', { type }),
    selfOnboardingContactMail: (type: string): void => ga('generic_event', 'self_onboarding_contact_mail', 'Self Onboarding', { type }),
    selfOnboardingAddingEmailConfirmed: (type: string): void => ga('generic_event', 'self_onboarding_adding_email_confirmed', 'Self Onboarding', { type }),
    selfOnboardingScanInput: (type: string): void => ga('generic_event', 'self_onboarding_scan_input', 'Self Onboarding', { type }),
    selfOnboardingInfo: (type: string): void => ga('generic_event', 'self_onboarding_info', 'Self Onboarding', { type }),
    selfOnboardingSuccess: (type: string): void => ga('generic_event', 'self_onboarding_success', 'Self Onboarding', { type }),
    selfOnboardingFail: (type: string): void => ga('generic_event', 'self_onboarding_fail', 'Self Onboarding', { type }),

    // Settings category
    defaultSettingsClick: (): void => ga('generic_event', 'default_settings_click', 'Settings'),
    languageChanged: (from_ln: string, to_ln: string): void =>
        ga('generic_event', 'language_changed', 'Settings', {
            language_changed: { from_ln, to_ln },
        }),
    settingsSmartHomeFaqClick :(): void => ga('generic_event', 'settings_smart_home_faq_click', 'Settings'),
    settingsSmartHomeLightwaveConnectClick :(): void => ga('generic_event', 'settings_smart_home_lightwave_connect_click', 'Settings'),
    settingsSmartHomePairSmartLights :(): void => ga('generic_event', 'settings_smart_home_pair_smart_lights', 'Settings'),
    settingsSmartHomePairTado :(): void => ga('generic_event', 'settings_smart_home_pair_tado', 'Settings'),
    settingsSmartHomeTadoConnectClick :(): void => ga('generic_event', 'settings_smart_home_tado_connect_click', 'Settings'),
    settingsSmartHomeUnlinkSmartLights :(): void => ga('generic_event', 'settings_smart_home_unlink_smart_lights', 'Settings'),
    settingsSmartHomeUnlinkTado :(): void => ga('generic_event', 'settings_smart_home_unlink_tado', 'Settings'),
    settingsEvChange :(): void => ga('generic_event', 'settings_ev_change', 'Settings'),
    settingsEvClick :(): void => ga('generic_event', 'settings_ev_click', 'Settings'),
    settingsEvLink :(): void => ga('generic_event', 'settings_ev_link', 'Settings'),
    settingsEvLinkFailedRetry :(): void => ga('generic_event', 'settings_ev_link_failed_retry', 'Settings'),
    settingsEvLinkSuccessContinue :(): void => ga('generic_event', 'settings_ev_link_success_continue', 'Settings'),
    settingsEvSave :(): void => ga('generic_event', 'settings_ev_save', 'Settings'),
    settingsEvUnlink :(): void => ga('generic_event', 'settings_ev_unlink', 'Settings'),
    privacyPageClick :(): void => ga('generic_event', 'privacy_click', 'Settings'),
    accountDeleteClick: (): void => ga('generic_event', 'delete_account_click', 'Settings'),
    accountDeleteDeclined: (): void => ga('generic_event', 'account_delete_declined', 'Settings'),
    accountDeleted: (): void => ga('generic_event', 'account_deleted', 'Settings'),
    themePreferences: (): void => ga('generic_event', 'theme_preferences', 'Settings'),
    dataRequest: (): void => ga('generic_event', 'data_request', 'Settings'),
    termsOfUse: (): void => ga('generic_event', 'terms_of_use', 'Settings'),
    faqs: (): void => ga('generic_event', 'faqs', 'Settings'),
    searchInFaqs: (): void => ga('generic_event', 'search_in_faqs', 'Settings'),
    noResultsInFaq: (): void => ga('generic_event', 'no_results_in_faq', 'Settings'),
    contact: (): void => ga('generic_event', 'contact', 'Settings'),
    acknowledgement: (): void => ga('generic_event', 'acknowledgement', 'Settings'),

    // Smart Home Category
    smartHomeLightsOn: (): void => ga('generic_event', 'smart_home_lights_on', 'Smart Home'),
    smartHomeLightsOff: (): void => ga('generic_event', 'smart_home_lights_off', 'Smart Home'),
    smartHomeLightsAdjusted: (): void => ga('generic_event', 'smart_home_lights_adjusted', 'Smart Home'),
    smartHomeSocketOn: (): void => ga('generic_event', 'smart_home_socket_on', 'Smart Home'),
    smartHomeSocketOff: (): void => ga('generic_event', 'smart_home_socket_off', 'Smart Home'),
    smartHomeWaterOff: (): void => ga('generic_event', 'smart_home_water_off', 'Smart Home'),
    smartHomeWaterOn: (): void => ga('generic_event', 'smart_home_water_on', 'Smart Home'),
    smartHomeHeatingOff: (): void => ga('generic_event', 'smart_home_heating_off', 'Smart Home'),
    smartHomeHeatingOn: (): void => ga('generic_event', 'smart_home_heating_on', 'Smart Home'),
    smartHomeCoolingAdjusted: (): void => ga('generic_event', 'smart_home_cooling_adjusted', 'Smart Home'),
    smartHomeCoolingOff: (): void => ga('generic_event', 'smart_home_cooling_off', 'Smart Home'),
    smartHomeCoolingOn: (): void => ga('generic_event', 'smart_home_cooling_on', 'Smart Home'),
    smartHomeRefresh: (): void => ga('generic_event', 'smart_home_refresh', 'Smart Home'),
    smartHomeSocketHistory: (): void => ga('generic_event', 'smart_home_socket_history', 'Smart Home'),
    smartHomeSocketResolution: (): void => ga('generic_event', 'smart_home_socket_resolution', 'Smart Home'),
    smartHomeSortBy: (sort: string): void => ga('generic_event', 'smart_home_sort_by', 'Smart Home', { smart_home_sort_by: { sort } } ),
    smartHomeCoolingHistory: (): void => ga('generic_event', 'smart_home_cooling_history', 'Smart Home'),
    smartHomeHeatingHistory: (): void => ga('generic_event', 'smart_home_heating_history', 'Smart Home'),
    smartHomeInsightsSlideViewTop: (): void => ga('generic_event', 'smart_home_insights_slide_view_top', 'Smart Home'),
    smartHomeInsightsAddDevice: (): void => ga('generic_event', 'smart_home_insights_add_device', 'Smart Home'),
    smartHomeInsightsSlideViewBottom: (): void => ga('generic_event', 'smart_home_insights_slide_view_bottom', 'Smart Home'),

    // Solar & Battery category
    pvBottomRangeChanged: (res: string): void => ga('generic_event', 'pv_bottom_range_changed', 'Solar & Battery', { pv_bottom_range_changed: { res } }),
    pvCalendar: (): void => ga('generic_event', 'pv_calendar', 'Solar & Battery'),
    pvChartNavigate: (): void => ga('generic_event', 'pv_chart_navigate', 'Solar & Battery'),
    pvChartSlide: (): void => ga('generic_event', 'pv_chart_slide', 'Solar & Battery'),
    pvChartToggle: (): void => ga('generic_event', 'pv_chart_toogle', 'Solar & Battery'),
    pvClick: (): void => ga('generic_event', 'pv_click', 'Solar & Battery'),
    pvCO2Faq: (): void => ga('generic_event', 'pv_CO2_faq', 'Solar & Battery'),
    pvDataDownload: (): void => ga('generic_event', 'pv_data_download', 'Solar & Battery'),
    pvDataDownloadCancel: (): void => ga('generic_event', 'pv_data_download_cancel', 'Solar & Battery'),
    pvDataRequest: (): void => ga('generic_event', 'pv_data_request', 'Solar & Battery'),
    pvExportedTurnedOff: (): void => ga('generic_event', 'pv_exported_turned_off', 'Solar & Battery'),
    pvExportedTurnedOn: (): void => ga('generic_event', 'pv_exported_turned_on', 'Solar & Battery'),
    pvPreviousDayOff: (): void => ga('generic_event', 'pv_previous_day_off', 'Solar & Battery'),
    pvPreviousDayOn: (): void => ga('generic_event', 'pv_previous_day_on', 'Solar & Battery'),
    pvTopRangeChange: (): void => ga('generic_event', 'pv_top_range_change', 'Solar & Battery'),
    pvForecastOn: (): void => ga('generic_event', 'pv_forecast_on', 'Solar & Battery'),
    pvForecastOff: (): void => ga('generic_event', 'pv_forecast_off', 'Solar & Battery'),
    generationToStorageSwitch: (): void => ga('generic_event', 'generation_to_storage_switch', 'Solar & Battery'),
    storageViewDischargingOn: (): void => ga('generic_event', 'storage_view_discharging_on', 'Solar & Battery'),
    storageViewDischargingOff: (): void => ga('generic_event', 'storage_view_discharging_off', 'Solar & Battery'),
    storageViewStateOfChargeOn: (): void => ga('generic_event', 'storage_view_state_of_charge_on', 'Solar & Battery'),
    storageViewStateOfChargeOff: (): void => ga('generic_event', 'storage_view_state_of_charge_off', 'Solar & Battery'),
    storageViewPreviousDayOn: (): void => ga('generic_event', 'storage_view_previous_day_on', 'Solar & Battery'),
    storageViewPreviousDayOff: (): void => ga('generic_event', 'storage_view_previous_day_off', 'Solar & Battery'),

    // Step-up Registration category
    stepUpRegistrationSave: (): void => ga('generic_event', 'step_up_reg_save', 'Step-up Registration'),
    stepUpRegistrationTerms: (): void => ga('generic_event', 'step_up_reg_terms', 'Step-up Registration'),

    // Top bar category
    logoClick: (): void => ga('generic_event', 'logo_click', 'Top bar'),

    // Tariff category
    manageTariffRateDetails: (): void => ga('generic_event', 'manage_tariff_rate_details', 'Tariff'),
    saveTariffRateDetails: (): void => ga('generic_event', 'save_tariff_rate_details', 'Tariff'),
    addNewTariffInterval: (): void => ga('generic_event', 'add_new_tariff_interval', 'Tariff'),
    deleteTariffDetail: (): void => ga('generic_event', 'delete_tariff_detail', 'Tariff'),
    editPrice: (): void => ga('generic_event', 'edit_price', 'Tariff'),

    // Insights category
    pvInsights: (): void => ga('generic_event', 'pv_insights', 'Insights'),
    evInsights: (): void => ga('generic_event', 'ev_insights', 'Insights'),
    viewAllChargingSessions: (): void => ga('generic_event', 'view_all_charging_sessions', 'Insights'),
    chargingHistoryMonthResolutionChange: (): void => ga('generic_event', 'charging_history_month_resolution_change', 'Insights'),
    chargingHistoryYearResolutionChange: (): void => ga('generic_event', 'charging_history_year_resolution_change', 'Insights'),
    chargingHistoryDataRangeChange: (): void => ga('generic_event', 'charging_history_data_range_change', 'Insights'),
    travelCostInformation: (): void => ga('generic_event', 'travel_cost_information', 'Insights'),

    // Smart Charging Category
    activateSmartCharging: (): void => ga('generic_event', 'activate_smart_charging', 'Smart Charging'),
    deactivateSmartCharging: (): void => ga('generic_event', 'deactivate_smart_charging', 'Smart Charging'),
    personalizeSmartCharging: (): void => ga('generic_event', 'personalize_smart_charging', 'Smart Charging'),
    saveSmartCharging: (): void => ga('generic_event', 'save_smart_charging', 'Smart Charging'),
    discardSmartChargingChanges: (): void => ga('generic_event', 'discard_smart_charging_changes', 'Smart Charging'),
    activateFullChargeMode: (): void => ga('generic_event', 'activate_full_charge_mode', 'Smart Charging'),
    deactivateFullChargeMode: (): void => ga('generic_event', 'deactivate_full_charge_mode', 'Smart Charging'),
    dynamicPriceChargingActivate: (): void => ga('generic_event', 'dynamic_price_charging_activate', 'Smart Charging'),
    dynamicPriceChargingDeactivate: (): void => ga('generic_event', 'dynamic_price_charging_deactivate', 'Smart Charging'),
    solarChargingActivate: (): void => ga('generic_event', 'solar_charging_activate', 'Smart Charging'),
    solarChargingDeactivate: (): void => ga('generic_event', 'solar_charging_deactivate', 'Smart Charging'),
    // Electric Vehicle category
    evViewDataRangeChange: ():void => ga('generic_event', 'ev_view_data_range_change', 'Electric Vehicle'),
    evViewCalendarDataRangeChange: ():void => ga('generic_event', 'ev_view_calendar_data_range_change', 'Electric Vehicle'),
    evViewChartFlag: ():void => ga('generic_event', 'ev_view_chart_flag', 'Electric Vehicle'),
    evViewChartNavigation: ():void => ga('generic_event', 'ev_view_chart_navigation', 'Electric Vehicle'),
    evViewChartToggle: ():void => ga('generic_event', 'ev_view_chart_toggle', 'Electric Vehicle'),
    evViewPreviousWeekViewOn: ():void => ga('generic_event', 'ev_view_previous_week_view_on', 'Electric Vehicle'),
    evViewPreviousWeekViewOff: ():void => ga('generic_event', 'ev_view_previous_week_view_off', 'Electric Vehicle'),
};

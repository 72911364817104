import * as Sentry from '@sentry/react';

import { brand, UnknownFunction } from '@eon-home/react-library';

import { FetchError, ResponseError } from '@swagger-http';

import { store } from '@src/index';
import { IS_NOT_DEV } from '../constants';

export type UnknownFunctionDict = Record<string, UnknownFunction>;

export const debug: UnknownFunctionDict = (() =>
    Object.keys(console).reduce(
        (result: UnknownFunctionDict, method: string) => {
            result[method] =
                IS_NOT_DEV || typeof jest === 'object'
                    ? () => undefined
                    : (console as unknown as UnknownFunctionDict)[method];

            return result;
        },
        {},
    ))();

export const handleError = async <T extends ResponseError | FetchError>(
    e: T,
    message: string,
): Promise<T | string> => {
    let error: T | string = e?.message || 'Unable to parse error message.';
    let is401 = false;
    let is404 = false;

    try {
        let clone!: Response;

        if (e instanceof ResponseError) {
            clone = e.response.clone();
        }

        if (e instanceof FetchError) {
            clone = new Response(e.cause.message);
        }

        error = await clone.text();
        is401 = clone.status === 401;
        is404 = clone.status === 404;
    } catch (ex) {}

    debug.error(`${message} ${e} | Raw: ${error}`);

    if (is401 || is404) {
        return error;
    }

    try {
        const { name, site, customerId } = store.getState().user;

        // prettier-ignore
        const customerName = (key: string) => name ? { [key]: `${name.first} ${name.last}` } : {};

        Sentry.setUser({
            id: customerId,
            ...customerName('name'),
        });

        Sentry.captureException(e, {
            tags: {
                siteId: site.id,
                customerId,
                application: brand.appName,
                ...customerName('customerName'),
            },
        });
    } catch (ex) {
        debug.error('Unable to handle error.');
    }

    return error;
};

export const enum UserActionTypes {
    GET_SITES = 'GET_SITES',
    SET_EMAIL = 'SET_EMAIL',
    SET_USER_DATA = 'SET_USER_DATA',
    SET_REGISTRATION_DATE = 'SET_REGISTRATION_DATE',
    ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS',
    SET_HOME_PROFILE_SURVEY = 'SET_HOME_PROFILE_SURVEY',
    SET_ENCRYPTED_REFERENCE = 'SET_ENCRYPTED_REFERENCE',
    SET_COMPLETED_CMS_SURVEYS = 'SET_COMPLETED_CMS_SURVEYS',
    DELETE_ACCOUNT = 'DELETE_ACCOUNT',
    GET_PRIVACY_DATA = 'GET_PRIVACY_DATA',
    PRIVACY_DATA_ERROR = 'PRIVACY_DATA_ERROR',
    PRIVACY_DATA_REQUEST = 'PRIVACY_DATA_REQUEST',
    PRIVACY_DATA_LOADING = 'PRIVACY_DATA_LOADING',
    SET_MOBILE_APP_USAGE_COUNT = 'SET_MOBILE_APP_USAGE_COUNT',
    SET_BILLING_REGION_DATA = 'SET_BILLING_REGION_DATA',
    SET_BILLING_REGIONS_DATA = 'SET_BILLING_REGIONS_DATA',
    SET_GRIDX_STATUS = 'SET_GRIDX_STATUS',
    SET_CONSENT_DATA = 'SET_CONSENT_DATA',
    SET_SMART_CHARGING_INFO_POPUP_SEEN = 'SET_SMART_CHARGING_INFO_POPUP_SEEN',
}

export const enum SurveyType {
    CMS = 'cms',
    HOME_PROFILE = 'meter',
}

export const enum SurveyLink {
    HOME_PROFILE = 'home-profile',
}

export enum CustomerSegment {
    SOLAR = 'Solar',
    EMOBILITY = 'Emobility',
    HEATING_COOLING = 'Heating-Cooling',
    SMART_METER = 'Smart-Meter',
    NO_DEVICES = 'No-Devices',
}

import { Dispatch } from 'redux';

import * as ENV from '@config/env';

import { brand, Region, RegionToCIAMAppId } from '@eon-home/react-library';

import { Configuration } from '@swagger-http';

import { store } from '@src/index';
import moment from 'moment';
import { isLocalhost } from './helpers';
import { disconnectLiveData, requestLogout } from '@store/actions';
import {
    API_URL_DEFAULT,
    CIAM_LOGOUT,
    LOCALE,
    LOGIN_URL,
    REFRESH_TOKEN,
    REGION,
    SCOPES,
    TOKEN,
    TOKEN_THRESHOLD,
} from '../constants';
import {
    CognitoAccessToken,
    tryDecodeToken,
    tryGetLogoutLink,
    tryGetTokenExpiry,
} from '@tools/utils/oauth';

export const getAccessToken = (): string =>
    ENV.accessToken ||
    store.getState().auth.token ||
    localStorage.getItem(TOKEN) ||
    '';

export const generateAccessTokenThreshold = (accessToken: string): number =>
    tryGetTokenExpiry(accessToken) ?? moment().add(40, 'minutes').unix();

export const setTokenInLocalStorage = (token: string, refreshToken: string) => {
    localStorage.setItem(TOKEN, token);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    localStorage.setItem(
        TOKEN_THRESHOLD,
        generateAccessTokenThreshold(token).toString(),
    );
};

export const createRequestConfiguration = (
    basePath = API_URL_DEFAULT,
): Configuration => {
    const accessToken = getAccessToken();

    return new Configuration({
        basePath,
        ...(accessToken
            ? {
                  accessToken: `Bearer ${accessToken}`,
              }
            : {}),
    });
};

export const cleanUpLocalStorage = () => {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(SCOPES);
    localStorage.removeItem(LOCALE);
    localStorage.removeItem(REGION);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(TOKEN_THRESHOLD);
};

export const goToLogin = (params: string = ''): void => {
    const port = brand.whitelabel ? 5200 : 4200;
    const url: string = isLocalhost
        ? `http://localhost:${port}/login`
        : LOGIN_URL;
    const finalUrl = url + (params ? '?error=' + params : '');
    if (window.location.href !== finalUrl) {
        window.location.href = finalUrl;
    }
};

export const logOut = async (dispatch: Dispatch<any>): Promise<void> => {
    disconnectLiveData();

    const appid = (RegionToCIAMAppId as any)[
        localStorage.getItem(REGION) as Region
    ];

    // Handle Logout flow for Croatia using HEMS SP
    const token = localStorage.getItem(TOKEN);
    const decodedJwtToken = token ? tryDecodeToken(token) : null;
    if (decodedJwtToken) {
        goToHemsSPLogout(decodedJwtToken);
        return;
    }

    const isFoton = window.location.href.includes('foton');
    const excludedBrands = ['E.ON Croatia', 'E.ON Next Home'];
    const isExcludedBrand = excludedBrands.includes(brand.brand);

    const shouldRedirectToCIAMLogout = !(isFoton || isExcludedBrand);

    dispatch(
        requestLogout(
            shouldRedirectToCIAMLogout ? goToCIAMLogout(appid) : goToLogin,
        ),
    );
};

export const goToCIAMLogout = (appid: string): (() => void) => {
    return () => {
        window.location.href = CIAM_LOGOUT + '?appid=' + appid;
    };
};

export const goToHemsSPLogout = (token: CognitoAccessToken) => {
    tryGetLogoutLink(token).then((logoutUrl) => {
        if (logoutUrl) {
            cleanUpLocalStorage();
            window.location.href = logoutUrl;
        }
    });
};

export enum TimeOfUseActionTypes {
    TOU_STATUS = 'TOU_STATUS',
    TOU_BATTERY_STATE = 'TOU_BATTERY_STATE',
    TOU_BATTERY_DECISION = 'TOU_BATTERY_DECISION',
    TOU_BATTERY_ERROR = 'TOU_BATTERY_ERROR',
    TOU_READINESS = 'TOU_READINESS',
}

export enum TimeOfUseStatus {
    ONLINE = 'ONLINE',
    SUBOPTIMAL = 'SUBOPTIMAL',
    OFFLINE = 'OFFLINE',
    DISABLED = 'DISABLED',
    INITIALIZING = 'INITIALIZING',
}

export enum TimeOfUseErrorCodes {
    FAILED_TO_SET_SYSTEM_TARIFF = 'Failed to set system tariff',
    FAILED_TO_SET_TARIFF_TOU_SETTINGS = 'Failed to set tariff and ToU settings',
    FAILED_TO_ENABLE_TOU = 'Failed to enable time of use settings',
}

import { CMSDataTypes as CMSV2DataTypes } from '@store/branches/cms/v2/types';
import { ExtendedLocale } from '../enums';

const { CMS_BASE_PATH } = process.env;

export const CMS_BASE = CMS_BASE_PATH;
export const CMS_BASE_URL = CMS_BASE + '/api/v1/public_cms/';

export const CMS_TERMS_NAME = 'TermsAndConditions';
export const CMS_PRIVACY_NAME = 'PrivacyPolicy';

export const CMS_FAQ_NAME = 'FAQ';
export const CMS_MARKETING_NAME = 'Marketing';
export const CMS_MARKETING_SECTION_NAME = 'MarketingSection';
export const CMS_CONTACT_PAGE_NAME = 'ContactPage';
export const CMS_PRE_INSTALLATION_NAME = 'Step';
export const CMS_SURVEYS_NAME = 'Survey';
export const CMS_INCIDENT_MESSAGES_NAME = 'IncidentMessage';
export const CMS_HEATING_INSIGHTS = 'HomeHeatingInsights';

export const CMS_URLS: { [key in CMSV2DataTypes]: string } = {
    [CMSV2DataTypes.PRE_INSTALLATION_SALE]:
        CMS_BASE_URL + CMS_PRE_INSTALLATION_NAME,
    [CMSV2DataTypes.MARKETING]: CMS_BASE_URL + CMS_MARKETING_NAME,
    [CMSV2DataTypes.MARKETING_SECTION]:
        CMS_BASE_URL + CMS_MARKETING_SECTION_NAME,
    [CMSV2DataTypes.TERMS]: CMS_BASE_URL + CMS_TERMS_NAME,
    [CMSV2DataTypes.CONTACT]: CMS_BASE_URL + CMS_CONTACT_PAGE_NAME,
    [CMSV2DataTypes.FAQ]: CMS_BASE_URL + CMS_FAQ_NAME,
    [CMSV2DataTypes.SURVEYS]: CMS_BASE_URL + CMS_SURVEYS_NAME,
    [CMSV2DataTypes.INCIDENT_MESSAGES]:
        CMS_BASE_URL + CMS_INCIDENT_MESSAGES_NAME,
    [CMSV2DataTypes.PRIVACY]: CMS_BASE_URL + CMS_PRIVACY_NAME,
    [CMSV2DataTypes.HEATING_INSIGHTS]: CMS_BASE_URL + CMS_HEATING_INSIGHTS,
};

export const CMS_LANGS: Record<ExtendedLocale, string> = {
    EN: 'en',
    GB: 'en',
    DE: 'de',
    IT: 'it',
    SE: 'sv',
    HU: 'hu',
    PL: 'pl',
    NL: 'nl',
    HR: 'hr',
};

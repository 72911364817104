import { StringOrNull } from '@tools/types';
import {
    CMSDataModel,
    CMSDataTypes as CMSV2DataTypes,
    CountryCode,
    FAQItem,
    MarketingItem,
    PreInstallationItem,
    CMSSurveyItem,
    CMSIncidentMessageItem,
    CMSIncidentMessage,
    CMSTermsItem,
    MarketingSection,
    HeatingInsights,
} from '@store/branches/cms/v2/types';
import { Dispatch } from 'redux';

type ExtractorParams = {
    data: CMSDataModel[];
    lang: CountryCode;
    dispatch?: Dispatch;
};
type Extractor = (
    params: ExtractorParams,
) =>
    | FAQItem[]
    | MarketingItem[]
    | MarketingSection[]
    | HeatingInsights[]
    | PreInstallationItem[]
    | CMSSurveyItem[]
    | CMSIncidentMessageItem[]
    | CMSTermsItem
    | string;

export const extractContactContent = ({ data, lang }: ExtractorParams) =>
    data.find((item) => item.fields[lang]?.content)?.fields[lang]?.content ??
    '';

export const sortCmsItems = (a: any, b: any): number =>
    (a?.order ?? 0) - (b?.order ?? 0);

export const extractFAQ = ({ data, lang }: ExtractorParams): FAQItem[] => {
    return data
        ? [...data].sort(sortCmsItems).map((item): FAQItem => {
              return {
                  category: item.fields[lang]?.category ?? '',
                  title: item.fields[lang]?.title ?? '',
                  content: parseAsHTML(item.fields[lang]?.content ?? '') ?? '',
                  slug: item.fields[lang]?.slug ?? '',
                  categorySlug: item.fields[lang]?.categorySlug ?? '',
              };
          })
        : [];
};

export const extractMarketing = ({ data, lang }: ExtractorParams) =>
    data.map((item) => ({
        type: item.fields[lang]?.type as MarketingItem['type'],
        sections: [],
        banner: {
            buttonLabel: item.fields[lang]?.buttonLabel ?? '',
            buttonUrl: item.fields[lang]?.buttonHyperLink ?? '',
            title: item.fields[lang]?.title ?? '',
            content: item.fields[lang]?.content ?? '',
            image: item.fields[lang]?.backgroundImage ?? '',
            slug: item.fields[lang]?.slug ?? '',
        },
    }));

export const extractMarketingSection = ({
    data,
    lang,
}: ExtractorParams): MarketingSection[] =>
    data.sort(sortCmsItems).map((item) => ({
        marketingGroupType: item.fields[lang]
            ?.marketingGroupType as MarketingItem['type'],
        buttonLabel: item.fields[lang]?.buttonLabel ?? '',
        buttonUrl: item.fields[lang]?.buttonHyperLink ?? '',
        title: item.fields[lang]?.title ?? '',
        content: item.fields[lang]?.content ?? '',
        frameLegend: item.fields[lang]?.frameLegend ?? '',
    }));

export const extractHeatingInsights = ({
    data,
    lang,
}: ExtractorParams): HeatingInsights[] =>
    data.map((item) => ({
        buttonLabel: item.fields[lang]?.buttonText ?? '',
        buttonUrl: item.fields[lang]?.buttonLink ?? '',
        title: item.fields[lang]?.title ?? '',
        content: item.fields[lang]?.content ?? '',
    }));

export const extractPreInstallation = ({
    data,
    lang,
}: ExtractorParams): PreInstallationItem[] =>
    data?.map(({ id, fields }) => ({
        id: id,
        title: fields?.[lang]?.title as string,
        message: fields?.[lang]?.message as string,
        confirmation: fields?.[lang]?.confirmation,
        stepName: fields?.[lang]?.stepName ?? '',
    }));

export const extractSurveys = ({
    data,
    lang,
}: ExtractorParams): CMSSurveyItem[] => {
    const payload = data
        ? data.map(({ id, fields }) => ({
              id: id,
              fields: fields?.[lang],
          }))
        : [];

    return payload as CMSSurveyItem[];
};

export const extractIncidentMessages = ({
    data,
    lang,
}: ExtractorParams): CMSIncidentMessageItem[] => {
    return data
        ? data.map(({ id, fields }) => ({
              id: id,
              fields: fields?.[lang] as CMSIncidentMessage,
          }))
        : [];
};

const extractTerms = ({ data, lang }: ExtractorParams): CMSTermsItem => {
    // TODO: change it in different task (related to Terms versioning )
    return {
        content: data[0]?.fields[lang]?.content as string,
    };
};

export const convertToHTML = (data: string): StringOrNull => {
    const parser: DOMParser = new DOMParser();
    const doc: Document = parser.parseFromString(data, 'text/html');

    return doc.body.innerHTML;
};

export const parseAsHTML = (markup: string): StringOrNull => {
    const parser: DOMParser = new DOMParser();
    const doc: Document = parser.parseFromString(markup, 'text/html');

    doc.querySelectorAll('a[href^="http"]:not([target])').forEach(
        (link: HTMLAnchorElement) => {
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer');
        },
    );

    return doc.body.innerHTML;
};

export const DataExtractorByType: Partial<{
    [P in CMSV2DataTypes]: Extractor;
}> = {
    [CMSV2DataTypes.FAQ]: extractFAQ,
    [CMSV2DataTypes.CONTACT]: extractContactContent,
    [CMSV2DataTypes.MARKETING]: extractMarketing,
    [CMSV2DataTypes.MARKETING_SECTION]: extractMarketingSection,
    [CMSV2DataTypes.PRE_INSTALLATION_SALE]: extractPreInstallation,
    [CMSV2DataTypes.SURVEYS]: extractSurveys,
    [CMSV2DataTypes.INCIDENT_MESSAGES]: extractIncidentMessages,
    [CMSV2DataTypes.TERMS]: extractTerms,
    [CMSV2DataTypes.HEATING_INSIGHTS]: extractHeatingInsights,
};

import { jwtDecode, JwtPayload } from 'jwt-decode';

/**
 * successful token response as obtained by oauth2 server
 */
export interface TokenSet {
    access_token: string;
    refresh_token: string;
}

/**
 * error response from oauth2 server
 */
interface TokenSetError {
    error: string;
}

/**
 * standard token claims with the cognito access token
 */
export type CognitoAccessToken = Required<JwtPayload> & {
    client_id: string;
};

/**
 * partial openid config
 */
interface OpenIdConfig {
    token_endpoint: string;
    end_session_endpoint: string;
}

/**
 * get the openid config from .well-known
 * @param issuer
 */
async function fetchOpenIdConfig(issuer: string): Promise<OpenIdConfig> {
    return fetch(`${issuer}/.well-known/openid-configuration`)
        .then((data) => data.json())
        .then((result) => {
            if (!result.token_endpoint) {
                throw new Error('No token endpoint found');
            }
            return result;
        });
}

/**
 * check if given token is a jwt and contains an issuer and client_id
 * @param accessToken
 */
export function tryDecodeToken(accessToken: string): CognitoAccessToken | null {
    try {
        const token = jwtDecode<CognitoAccessToken>(accessToken);
        if (!token.iss || !token.client_id) {
            return null;
        }
        return token;
    } catch {
        return null;
    }
}

/**
 * get expiry from token if it is a jwt and exp is defined
 * @param accessToken
 */
export function tryGetTokenExpiry(accessToken: string): number | null {
    return tryDecodeToken(accessToken)?.exp ?? null;
}

/**
 * try to decode the access token, and if it is a valid oauth2 token, attempt the token refresh
 *
 * if the token is not a cognito jwt at all, it will return null
 * @param accessToken
 * @param refreshToken
 */
export async function tryOauthTokenRefresh(
    accessToken: string,
    refreshToken: string,
): Promise<TokenSet | null> {
    const token = tryDecodeToken(accessToken);
    if (!token) {
        return null;
    }
    return fetchOpenIdConfig(token.iss)
        .then((config) =>
            fetch(config.token_endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    grant_type: 'refresh_token',
                    client_id: token.client_id,
                    refresh_token: refreshToken,
                }),
            }),
        )
        .then((data) => data.json())
        .then((result: TokenSet | TokenSetError) => {
            if (!('access_token' in result)) {
                throw new Error(`No access token: ${result?.error}`);
            }
            return {
                access_token: result.access_token,
                refresh_token: result.refresh_token ?? refreshToken,
            };
        });
}

/**
 * Try to get Logout Link from HEMS Service Identity Provider (Cognito) with JWT
 * */
export async function tryGetLogoutLink(
    token: CognitoAccessToken,
): Promise<string | null> {
    const client_id = token.client_id;
    const issuer = token.iss;

    if (!client_id || !issuer) {
        return null;
    }

    try {
        const config = await fetchOpenIdConfig(issuer);
        return `${config.end_session_endpoint}?client_id=${client_id}&logout_uri=${window.location.origin}/login`;
    } catch (error) {
        throw new Error(`No Logout Link: ${error.message}`);
    }
}

import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { RootState } from './types';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export * from './branches/cms/selectors';
export * from './branches/emobility/selectors';
export * from './branches/emobility/electric-car/selectors';
export * from './branches/emobility/wallbox/selectors';
export * from './branches/energy/selectors';
export * from './branches/energy-flow/selectors';
export * from './branches/live-data/selectors';
export * from './branches/notifications/selectors';
export * from './branches/settings/selectors';
export * from './branches/smart-home/selectors';
export * from './branches/spot-price/selectors';
export * from './branches/tariff/selectors';
export * from './branches/user/selectors';
export * from './branches/weather/selectors';
export * from './branches/time-of-use/selectors';

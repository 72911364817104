import './polyfills';

import TagManager from 'react-gtm-module';
import { FC, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Workbox } from 'workbox-window';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { Integrations } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { WorkboxLifecycleEvent } from 'workbox-window/utils/WorkboxEvent';
import fetchIntercept, { FetchInterceptorResponse } from 'fetch-intercept';
import {
    matchRoutes,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
} from 'react-router';

import { isAdminPortal } from '@eon-home/react-library';

import { ResponseError } from '@swagger-http';

import i18n from '@src/i18n';
import packageMeta from '../package.json';

import { App } from '@components';
import { getNewToken } from '@store/actions';
import { configureStore } from '@store';
import { SettingsActionTypes } from '@store/enums';
import {
    IS_PROD,
    IS_MASTER,
    GOOGLE_TAG_MANAGER_ID,
    CONSENT_COOKIE,
} from '@tools/constants';
import { logOut, goToLogin, handleError, isLocalhost } from '@tools/utils';
import { initNewRelic } from '@src/newrelic';

if (IS_MASTER && !isAdminPortal() && !isLocalhost) {
    Sentry.init({
        dsn: 'https://df665a678f92481e9f5c2453f3f74b38@sentry.eon.com/55',
        release: `${packageMeta.name}@${packageMeta.version}`,
        ignoreErrors: [
            // The following errors list is taken from the Sentry Tips & Tricks page
            // which leads to the following gist in Github: https://gist.github.com/impressiver/5092952
            //
            // Random plugins/extensions
            'top.GLOBALS',
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            'http://tt.epicplay.com',
            "Can't find variable: ZiteReader",
            'jigsaw is not defined',
            'ComboSearch is not defined',
            'http://loading.retry.widdit.com/',
            'atomicFindClose',
            // Facebook borked
            'fb_xd_fragment',
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage',
            // Generic error code from errors outside the security sandbox
            // You can delete this if using raven.js > 1.0, which ignores these automatically.
            'Script error.',
            // ---
            // Offline plugin adds the `__uncache=DATE_IN_ISO_URL_ENCODED_STRING` argument to the path of
            // each cached file. When a new version of the file is found, Offline plugin invalidates the previous
            // version and removes it from the browser cache. This is where Firefox and Safari throw errors because
            // apparently they try to load the files. These kinds of errors have no impact on the user experience
            // and the user interface because the browsers load the latest version of the files provided by the
            // Offline plugin.
            // That's why we don't need to know about them and we filter them out.
            /.*(\?__uncache=\d.).*/,
            // ---
            // Due to the way we redeploy the app and the caching implemented using service workers,
            // users may have an outdated version of the chunks which will cause Sentry to throw this error,
            // The service worker will download the correct chunk but Sentry will still report this error.
            // We don't want such noise.
            'ChunkLoadError',
            'Loading CSS chunk',
            // ---
            // Firefox throws this error upon subsequent service worker registration
            // https://stackoverflow.com/questions/11768221/firefox-websocket-security-issue/12042843#12042843
            'The operation is insecure.',
            // ---
            // This happens when the user navigates away from the page or closes a tab while a network request is still pending
            // In this case the browser aborts the fetch request which throws this error
            'Fetch is aborted',
            // ---
            // Chromium based browsers and Firefox report aborted/cancelled network requests as failed
            // In our case this happens when redirecting from the login/oauth route to the main app
            // and all CMS requests get aborted/cancelled.
            'Failed to fetch',
            'TypeError: Failed to fetch',
            'NetworkError when attempting to fetch resource.',
            'TypeError: NetworkError when attempting to fetch resource.',
        ],
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
        ],
        initialScope: {
            tags: {
                branch: process.env.BRANCH,
            },
        },
        normalizeDepth: 10,
        tracesSampleRate: 1,
    });
}
if (!isAdminPortal() && !isLocalhost) {
    initNewRelic();
}

export const store = configureStore();

if (localStorage.getItem(CONSENT_COOKIE) === '0') {
    TagManager.initialize({
        gtmId: GOOGLE_TAG_MANAGER_ID,
    });
}

const isAuthRoute = (url: string): boolean =>
    url.includes('refresh') ||
    url.includes('sign-in') ||
    url.includes('sign-out');

let isRefreshing = false;

const node = document.getElementById('root') || document.createElement('div');
const root = createRoot(node);

fetchIntercept.register({
    // prettier-ignore
    request: (url: string, config: RequestInit): [string, RequestInit] => [url, config],
    requestError: (error: Error): Promise<Error> => Promise.reject(error),
    response: (
        response: FetchInterceptorResponse,
    ): FetchInterceptorResponse => {
        if (response.status !== 401) {
            return response;
        }

        const cleanUp = () => {
            logOut(store.dispatch);
            goToLogin();
        };

        if (isAuthRoute(response.url)) {
            cleanUp();

            return response;
        }

        if (!isRefreshing) {
            isRefreshing = true;

            getNewToken(store)
                .then(() => window.location.reload())
                .catch(cleanUp)
                .finally(() => {
                    isRefreshing = false;
                });
        }

        return response;
    },
    responseError: (error: Error): Promise<Error> => Promise.reject(error),
});

const renderRoot = (Application: FC): void => {
    root.render(
        <HelmetProvider>
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <BrowserRouter>
                        <Application />
                    </BrowserRouter>
                </Provider>
            </I18nextProvider>
        </HelmetProvider>,
    );
};

if (IS_PROD) {
    if ('serviceWorker' in navigator) {
        const sw = 'service-worker.js';
        const wb = new Workbox(`/${sw}`);

        const previousVersion = localStorage.getItem('version');
        const currentVersion = packageMeta.version;

        // checking if an old version is stored in localStorage
        // - if it exists and differs from the current version, prompt the user to update
        // - if no previous version is found, assume this is the user's first visit, meaning
        //   they are already seeing the latest version without any cached resources
        const isNewRelease =
            previousVersion && previousVersion !== currentVersion;

        const onServiceWorkerUpdate = (condition?: boolean): void => {
            if (!condition || !isNewRelease) return;

            store.dispatch({
                type: SettingsActionTypes.OPEN_UPDATE_MODAL,
            });
        };

        wb.addEventListener('installed', (event: WorkboxLifecycleEvent) => {
            onServiceWorkerUpdate(
                event.isUpdate && event.sw?.scriptURL.includes(sw),
            );
        });

        wb.addEventListener('waiting', (event: WorkboxLifecycleEvent) => {
            onServiceWorkerUpdate(event.sw?.scriptURL.includes(sw));
        });

        wb.register().catch((e) => e);

        // store the version if it's missing (first visit)
        if (!previousVersion) {
            localStorage.setItem('version', currentVersion);
        }
    }
}

window.addEventListener('unhandledrejection', async (event) => {
    event.preventDefault();

    await handleError(
        new ResponseError(new Response(await event.promise)),
        `UNHANDLED PROMISE REJECTION: ${event.reason}`,
    );
});

renderRoot(App);

import { accessToken } from '@config/env';
import { AuthActionTypes } from '@store/enums';
import { TOKEN, REFRESH_TOKEN } from '@tools/constants';
import { AuthState, AuthAction } from '@store/types';
import { generateAccessTokenThreshold } from '@tools/utils';

export function defineInitialState() {
    const token = (accessToken || localStorage.getItem(TOKEN)) ?? '';
    return {
        token,
        threshold: generateAccessTokenThreshold(token) || 0,
        refreshToken: localStorage.getItem(REFRESH_TOKEN) || '',
        isLoggingOut: false,
    };
}

export const initialState: AuthState = defineInitialState();

export default function reducer(
    state: AuthState = initialState,
    action: AuthAction,
): AuthState {
    switch (action.type) {
        case AuthActionTypes.SET_TOKEN:
            return {
                ...state,
                ...action.payload,
            };
        case AuthActionTypes.INITIATE_LOGOUT: {
            return {
                ...state,
                isLoggingOut: true,
            };
        }
        default:
            return state;
    }
}

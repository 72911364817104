import { Dispatch } from 'redux';

import { Locale } from '@eon-home/react-library';

import { ResponseError } from '@swagger-http';

import { ExtendedLocale } from '@tools/enums';
import { getRegion, handleError } from '@tools/utils';
import { CMSAction } from '@store/types';
import { CMS_LANGS } from '@tools/constants';
import { CMS_URLS } from '@tools/constants/cms-v2';
import { CMSActionTypes } from '@store/enums';
import {
    CMSDataTypes as CMSV2DataTypes,
    CountryCode,
} from '@store/branches/cms/v2/types';
import { DataExtractorByType, convertToHTML } from './helpers';

type AsyncArrowFn<T> = (dispatch?: Dispatch) => Promise<T>;

export const CMSLegalData = [
    CMSV2DataTypes.PRIVACY,
    CMSV2DataTypes.TERMS,
    CMSV2DataTypes.CONTACT,
];

export const cmsSetError = (type: CMSV2DataTypes): CMSAction => ({
    type: CMSActionTypes.ERROR,
    payload: {
        [type]: {
            data: [],
            error: true,
            loading: false,
        },
    },
});

export const cmsSetLoading = (type: CMSV2DataTypes): CMSAction => ({
    type: CMSActionTypes.LOADING,
    payload: {
        [type]: {
            data: [],
            error: false,
            loading: true,
        },
    },
});

export const cmsSetData = (data: any, type: CMSV2DataTypes): CMSAction => ({
    type: CMSActionTypes.SET_DATA,
    payload: {
        [type]: {
            data,
            error: false,
            loading: false,
        },
    },
});

export const buildUrl = (url: string, lang: string) =>
    lang
        ? url + '&lang=' + CMS_LANGS[lang.toUpperCase() as ExtendedLocale]
        : url;

export const cmsGetData =
    (
        url: string,
        type: CMSV2DataTypes,
        tenantId: string,
        locale: string | Locale,
    ): AsyncArrowFn<string | void> =>
    async (dispatch: Dispatch<any>): Promise<string | void> => {
        dispatch(cmsSetLoading(type));
        const reqLocale = CMSLegalData.includes(type)
            ? CMS_LANGS[getRegion() as ExtendedLocale]
            : locale;

        url += `?tenantId=${tenantId}&language=${reqLocale}`;

        try {
            const content = await fetch(url)
                .then((response) => {
                    // Catches errors when using the fetch method.
                    // Otherwise, by default, in case of an error status -
                    // fetch proceeds with the next `.then()`.
                    // {@link https://stackoverflow.com/a/54118576/1333836}
                    if (response.status !== 200) {
                        throw response;
                    }

                    return response;
                })
                .then((response) => response.json())
                .then((response) => {
                    const extractData = DataExtractorByType[type] ?? null;

                    if (extractData !== null) {
                        return extractData({
                            data: response ?? [],
                            lang: reqLocale as CountryCode,
                            dispatch,
                        });
                    }

                    return convertToHTML(
                        (response?.[0]?.fields[reqLocale]?.[
                            'content'
                        ] as string) || '',
                    );
                });
            dispatch(cmsSetData(content, type));
        } catch (e) {
            dispatch(cmsSetError(type));

            await handleError(
                new ResponseError(
                    new Response('Error when fetching data from CMS.'),
                ),
                'Error when fetching data from CMS:',
            );
        }
    };

export const fetchCMSData =
    (tenantId: string, locale: Locale): AsyncArrowFn<string | void> =>
    async (dispatch: Dispatch<any>): Promise<string | void> => {
        Promise.all([
            dispatch(
                cmsGetData(
                    CMS_URLS.contact,
                    CMSV2DataTypes.CONTACT,
                    tenantId,
                    locale,
                ),
            ),
            dispatch(
                cmsGetData(CMS_URLS.faq, CMSV2DataTypes.FAQ, tenantId, locale),
            ),
            dispatch(
                cmsGetData(
                    CMS_URLS.marketing,
                    CMSV2DataTypes.MARKETING,
                    tenantId,
                    locale,
                ),
            ),
            dispatch(
                cmsGetData(
                    CMS_URLS.marketingSection,
                    CMSV2DataTypes.MARKETING_SECTION,
                    tenantId,
                    locale,
                ),
            ),
            dispatch(
                cmsGetData(
                    CMS_URLS.surveys,
                    CMSV2DataTypes.SURVEYS,
                    tenantId,
                    locale,
                ),
            ),
            dispatch(
                cmsGetData(
                    CMS_URLS.incidentMessages,
                    CMSV2DataTypes.INCIDENT_MESSAGES,
                    tenantId,
                    locale,
                ),
            ),
            dispatch(
                cmsGetData(
                    CMS_URLS.sales,
                    CMSV2DataTypes.PRE_INSTALLATION_SALE,
                    tenantId,
                    locale,
                ),
            ),
            dispatch(
                cmsGetData(
                    CMS_URLS.privacy,
                    CMSV2DataTypes.PRIVACY,
                    tenantId,
                    locale,
                ),
            ),
            dispatch(
                cmsGetData(
                    CMS_URLS.terms,
                    CMSV2DataTypes.TERMS,
                    tenantId,
                    locale,
                ),
            ),
            dispatch(
                cmsGetData(
                    CMS_URLS.heatingInsights,
                    CMSV2DataTypes.HEATING_INSIGHTS,
                    tenantId,
                    locale,
                ),
            ),
        ]);
    };

export const fetchSalesCMSData =
    (tenantId: string, locale: Locale): AsyncArrowFn<string | void> =>
    async (dispatch: Dispatch<any>): Promise<string | void> => {
        dispatch(
            cmsGetData(
                CMS_URLS.sales,
                CMSV2DataTypes.PRE_INSTALLATION_SALE,
                tenantId,
                locale,
            ),
        );
    };

import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

let sessionAgent: BrowserAgent;

export const initNewRelic = (): void => {
    const newRelicAppId = process.env?.NEW_RELIC_APP_ID;
    if (newRelicAppId && !sessionAgent) {
        const options = {
            init: {
                distributed_tracing: {
                    enabled: true,
                    cors_use_newrelic_header: true,
                    cors_use_tracecontext_headers: true,
                    allowed_origins: [process.env.API_URL_DEFAULT],
                },
                privacy: { cookies_enabled: true },
                ajax: { deny_list: ['bam.eu01.nr-data.net'] },
            },
            info: {
                beacon: 'bam.eu01.nr-data.net',
                errorBeacon: 'bam.eu01.nr-data.net',
                licenseKey: 'NRBR-5d49fdefd22c7bd13c5',
                applicationID: newRelicAppId,
                sa: 1,
            },
            loader_config: {
                accountID: '4490158',
                trustKey: '4490158',
                agentID: newRelicAppId,
                licenseKey: 'NRBR-5d49fdefd22c7bd13c5',
                applicationID: newRelicAppId,
            },
        };
        sessionAgent = new BrowserAgent(options);
    }
};
